import * as React from 'react';
import Logo from "../../assets/images/alpha_icon.png";
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';
import navigateTo from '../../utils/navigateTo';
import { motion } from 'framer-motion';

import closeIcon from '../../assets/icons/close.svg';
import burgerIcon from '../../assets/icons/burger.svg';
import './Header.scss';


import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import axios from 'axios';


import { useSelector } from 'react-redux';
import {mintEnabled, setweb3provider, resetweb3provider, setweb3Okx } from '../../action/CounterAction';
import reduxStore from '../../ReduxStore';
import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

import okxWeb3 from '@okwallet/extension';
import Modal from '../Modal/Modal';

const nftStakingAbi = require('../../helper/sacredshardstaking.json')
const sacredShardAbi = require('../../helper/sacredshard.json')


const menuBtn = {
  open: { rotate: 0 },
  closed: { rotate: 180 },
};

const nav = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '130%' },
};
const Header = ({ siteTitle }) => {
  const web3provider = useSelector(state => state.web3Provider);
  const address = useSelector(state => state.address);
  const isOkx = useSelector(state => state.isOkx);
  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false); // New state for modal
  const [sacredShardNft, setSacredShardNft] = React.useState([]);
  const [stakedSacredShardNft, setStakedSacredShardNft] = React.useState([]);

  const [isWhitelisted, setIsWhitelisted] = React.useState(false)

  const nftAddress = process.env.GATSBY_SACREDSHARD_ADDRESS
  const nftStakingAddress = process.env.GATSBY_STAKING_NFT_CONTRACT_ADDRESS
  function onNavigate(e) {
    navigateTo(e);
  }

  function handleOpen() {
    setOpen(!open);
  }

  const handleConnect = () => {
    setModalOpen(true); // Open modal when clicking connect
    console.log(isModalOpen); // Add this to debug
  };

  const handleCloseModal = () => {
    setModalOpen(false); // Close modal
  };

  async function getWeb3Modal() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "4a2b2f588c424e8c939bec2046a4d5c4",
        },
      },
    }
    const web3modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true,
      providerOptions, // required
    })
    return web3modal;
  }

  const connect = async () => {
      try {
        setModalOpen(false)
        const web3modal = await getWeb3Modal();
        const provider = await web3modal.connect() // display the modal

        console.log("provider",provider)
        const web3Provider = new ethers.providers.Web3Provider(provider)
        console.log(web3Provider)
        const signer = web3Provider.getSigner()
        const address = await signer.getAddress()
        console.log(address)
        const network = await web3Provider.getNetwork()
        reduxStore.dispatch(setweb3provider(provider, web3Provider, address, network))
        toast.success('Connected to Web3')
      } catch (e) {
        console.log('connect error', e)
      }
  }


  const connectOkx = async () => {
    try {
      setModalOpen(false)
      okxWeb3
      .init()
      .then((wallet) => {
        // return wallet account information
        console.log(wallet);

        const address = wallet.addresses.ethereum.address;
        reduxStore.dispatch(setweb3Okx(address))
      })
      .catch((error) => {
        // Error returned when rejected
        console.error(error);
      });
  
  
      // Check if window.ethereum is available
        // Pass the provider to ethers.js
        
        // Now, you can use the provider with ethers.js as desired
        // ...
  

  
    } catch (error) {
      // Error returned when rejected
      console.error(error);
    }
  }


  const disconnectOkx = async () => {
    try {
      okxWeb3.disconnect();
      reduxStore.dispatch(resetweb3provider());
      toast.success('Connected to Web3')
    } catch (e) {
      console.log('connect error', e)
    }
  }
  
  

  const disconnect = async () => {
    try {

      if(!isOkx){
          const web3modal = await getWeb3Modal();
          if (web3modal) {
            web3modal.clearCachedProvider()
            if (web3provider?.disconnect && typeof web3provider.disconnect === 'function') {
              await web3provider.disconnect()
            }
            reduxStore.dispatch(resetweb3provider());
          } else {
            console.error('No Web3Modal')
          }
            toast.success('Connected to Web3')
      }else{
        disconnectOkx()
        reduxStore.dispatch(resetweb3provider());
      }
    } catch (e) {
      console.log('connect error', e)
    }
  }

  const loadSacredShard = async() => {
    try{
    const query = `
      {
        sacredshardsNfts(first: 500, where: { owner: "${address}" }) {
              id
              owner
              tokenId
          }
      }
      `;
    const sacredShard = await axios.post('https://api.studio.thegraph.com/query/46966/sacredshards-nft-eth/version/latest', {query: query})

    let sacredshardNftData = sacredShard.data.data.sacredshardsNfts;

    setSacredShardNft(sacredshardNftData)

    console.log(sacredshardNftData)

    }catch(err){

    }
  }

  const loadStakedSacredShard = async() => {
    try{
      const signer = web3provider?.getSigner();
      const contract = new ethers.Contract(nftStakingAddress, nftStakingAbi, signer);

      const stakedNft = await contract.getStakerInfo(address, 0)

      let TokenIdArr = stakedNft[1];

      let stakedShard = []

      TokenIdArr = TokenIdArr.map((token) => {
        let obj = {}
        obj.tokenId = token.toNumber()
        stakedShard.push(obj)
      })
      console.log(TokenIdArr)

      setStakedSacredShardNft(stakedShard)

      console.log(stakedShard)
    }catch(err){

    }
  }

  React.useEffect(() => {
    // Load sacred shard data when the component mounts
    const loadTokenGatedData = async () => {
      try {
        // Check if the user owns Sacred Shards and update the state accordingly
        await loadSacredShard()
        await loadStakedSacredShard()
        console.log('token gated loaded')

        // Check if the user is whitelisted
        const response = await axios.get(`https://api.alphashards.com/api/is-whitelisted/${address}`);
        const { isWhitelisted } = response.data;

        setIsWhitelisted(isWhitelisted);

      } catch (error) {
        console.error('Error loading Sacred Shard data:', error);
      }
    };

    if (web3provider) {
      loadTokenGatedData();
    }
  }, [web3provider]);

  return (

    
    <header className='Header'>
      <div className="logo-container">
        <motion.button
          animate={open ? 'open' : 'closed'}
          initial={'closed'}
          variants={menuBtn}
          onClick={handleOpen}
          className='Header__menu__btn'
        >
          <img src={open ? closeIcon : burgerIcon} alt='' />
        </motion.button>
        <img id="logo" src={Logo} alt="Your logo" />
      </div>
      <div className="connect-button-container">
        { !address &&
          <button id="connect-button" onClick={handleConnect}>CONNECT WALLET</button>
        }
        { address &&
          <button id="connect-button" style={{fontSize:'12px'}} onClick={disconnect}>DISCONNECT WALLET</button>
        }
      </div>
      <motion.nav
        animate={open ? 'open' : 'closed'}
        initial={'closed'}
        variants={nav}
        className={`Header__motion__nav ${open ? 'open' : 'closed'}`}
      >
        <ul className='Header__motion__nav-anchors'>
          <li>
            <a href='/'>
              HOME
            </a>
          </li>
          <li>
            <a href='/staking'>
              STAKING
            </a>
          </li>
          <li>
            <a href='/sacredshardstaking'>
              SACRED SHARD STAKING
            </a>
          </li>
          <li>
            <a href='/marketplace'>
              MARKETPLACE
            </a>
          </li>
        </ul>
      </motion.nav>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="connect-button-container" style={{marginBottom:'20px'}}>
          <button id="connect-button" onClick={connect}>Connect Wallet</button>  
        </div>
        <div className="connect-button-container">
          <button id="connect-button" onClick={connectOkx}>OKX Wallet</button>  
        </div>
      </Modal>
    </header>
  );
};

export default Header;
