import * as React from "react"
import Header from "./Header/Header"
import "./Layout.scss"
import { Helmet } from "react-helmet"
import Preloader from "./Preloader/Preloader"

const Layout = ({ children }) => {
  const canvasRef = React.useRef()
  const [loaded, setLoaded] = React.useState(false)

  React.useEffect(() => {
    import('react-facebook-pixel')
      .then((m) => m.default)
      .then((ReactPixel) => {
        ReactPixel.init('711494146507802') // facebookPixelId
        ReactPixel.pageView()
      })

  }, [])

  const handleLoad = React.useMemo(() => {
    setLoaded(true)
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='viewport' content='width=device-width' />
        <meta name="viewport" content="width=device-width,initial-scale=1.0"></meta>
      </Helmet>
      <Header siteTitle={`Title`} />
      <Preloader handleLoad={handleLoad} />
      <div className='layer' />
      {/* <canvas ref={canvasRef} /> */}
      <main style={{ display: loaded ? 'block' : 'none' }}>{children}</main>
    </>
  )
}


export default Layout